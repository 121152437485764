<template>
  <div class="text-center" style="margin-top:40vh">
    <div v-if="!completed">
      <h2 class="mb-4">{{ $t('pickupReady.title') }}</h2>
      <v-btn color="primary" depressed @click.native="markAsReadyForPickUp" rounded>{{ $t('pickupReady.button') }}</v-btn>
    </div>
    <div v-else>
      <h2 class="mb-4">{{ $t('pickupReady.result') }}</h2>
    </div>
  </div>
</template>
<script>
import Service from '@/services'
export default {
  name: 'Ready',
  data () {
    return {
      orderId: this.$route.query.orderId,
      completed: false
    }
  },
  methods: {
    async markAsReadyForPickUp () {
      this.loading = true

      try {
        await Service.post(`/Orders/${this.orderId}/MarkAsReadyForPickUp`)

        this.completed = true
      } catch (error) {}

      this.loading = false
    }
  }
}
</script>
