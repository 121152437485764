<template>
  <div class="text-center" style="margin-top:40vh">
    <div v-if="!completed">
      <v-form ref="pickUpCode" v-model="valid">
      <h2 class="mb-4">{{ $t('pickupCompleted.title') }}</h2>
      <v-text-field v-model="code" :label="$t('pickupCompleted.label')" class="mb-4" style="width:250px; margin:auto" :rules="requiredText"></v-text-field>
      <v-btn color="primary" depressed @click.native="markAsReadyForPickUp" :disabled="loading || !valid" :loading="loading" rounded>{{ $t('pickupCompleted.button') }}</v-btn>
      </v-form>
    </div>
    <div v-else>
      <h2 class="mb-4">{{ $t('pickupCompleted.result') }}</h2>
      <p>{{ $t('pickupCompleted.end') }}</p>
    </div>
  </div>
</template>
<script>
import { displayErrors } from '@/utils/helpers'
import Service from '@/services'
export default {
  name: 'Completed',
  data () {
    return {
      valid: true,
      loading: false,
      orderId: this.$route.query.orderId,
      completed: false,
      code: null,
      requiredText: [
        v => !!v || this.$t('validation.fieldRequired'),
        v => (v || '').length === 6 || 'Niepoprawna ilość znaków'
      ]
    }
  },
  methods: {
    async markAsReadyForPickUp () {
      this.loading = true
      this.$refs.pickUpCode.validate()

      if (!this.valid) return

      try {
        await Service.post(`/Orders/${this.orderId}/MarkAsPickedUp`, { pickUpCode: this.code })

        this.completed = true
      } catch (error) {
        displayErrors('Wystąpił błąd')
      }

      this.loading = false
    }
  }
}
</script>
